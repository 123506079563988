import React from 'react'
import styles from './navbar.module.scss'
import { Link } from 'react-router-dom'
import {ReactComponent as Zunu_MessageLogo} from '../../../assets/svg/message_logo.svg'
import { useDownloadHook } from '../../hooks/download.hook'
import useNavbarHook from './navbar.hook'

function Navbar() {

    const {
        handleBuildDownload, }
        = useDownloadHook()

    const {
        os
    } = useNavbarHook()
    
    return (
        <nav className={styles.navbar}>
        <div className={styles.message_logoContainer}>
            <Link to="">
                <Zunu_MessageLogo className={styles.message_logo} />
            </Link>
        </div>
        <div className={styles.downloadBttnContainer}>
            <Link to="">
                <button onClick={() => handleBuildDownload(os)} id="navDownload"  className={styles.downloadBttn}>Download</button>
            </Link>
        </div>
    </nav>
    )
}

export { Navbar }